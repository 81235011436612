import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'


import { graphql } from 'gatsby'
import PageHeader from '../../components/PageHeader/PageHeader'
import Footer from '../../components/Footer/Footer'
import BlogMainSpecificCat from '../../components/BlogMainSpecificCat/BlogMainSpecificCat'
import HeaderProvider from '../../components/Header/HeaderProvider'
import SEO from '../../components/SEO'

const styles = {
  root: {},
}

const GuideCategory = props => {

  const { classes, data } = props
  const modified = []
  data.datoCmsCategory._allUrlLocales.map(item => {
    if (item.locale === 'de') {
      modified.push({ 'locale': item.locale, 'value': 'guide/kategorie/' + item.value })
    } else {
      modified.push({ 'locale': item.locale, 'value': 'guide/category/' + item.value })
    }

  })


  return (
    <div className={classes.root}>
      <SEO
        seoMetaTags={data.datoCmsCategory.seoMetaTags.tags}
        actLang={props.pageContext.locale}
        localURLS={modified}
      />
      <HeaderProvider
        localURLS={modified}
        actLang={props.pageContext.locale}
        SEOtitle={data.datoCmsCategory.category}
        SEOdescription={'category'}
        SEOimage={data.datoCmsCategory.featuredImage.fluid.src}
        SEOpathname={data.datoCmsCategory.url}
      />
      <PageHeader
        leftHeader
        image={data.datoCmsCategory.featuredImage.fluid}
        title={data.datoCmsCategory.category}
        subtitle={'Guide - Category'}
        alt={data.datoCmsCategory.featuredImage.alt}
      />
      <BlogMainSpecificCat actLang={props.pageContext.locale} allDatoCmsArticle={data.allDatoCmsArticle}/>
      <Footer
        actLang={props.pageContext.locale}
      />
    </div>
  )
}
export const query = graphql`
    query($slug: String! $locale: String!) {
        datoCmsCategory(url: { eq: $slug } locale:{eq:$locale}) {
            seoMetaTags{
                tags
            }
            _allUrlLocales{locale value}
            category
            url
            featuredImage {
                alt
                fluid {
                    src
                    ...GatsbyDatoCmsFluid_noBase64
                }
            }

        }
        allDatoCmsArticle(
            sort:{
                fields:meta___updatedAt
                order:DESC
            }
            filter:{
                meta:{status:{eq:"published"}  }
                categories:{elemMatch:{url:{eq: $slug}}}
                locale:{eq:$locale}
            }

        ){
            nodes{
                locale
                categories{ category url}
                url
                title
                meta{
                    updatedAt(formatString: "MM-DD-YYYY")
                }
                summary
                featuredImage{
                    alt
                    fluid(maxWidth:705){
                        src
                        ...GatsbyDatoCmsFluid_noBase64
                    }
                }
            }
        }
    }
`
export default withStyles(styles)(GuideCategory)
