import React from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import blogMainStyle from '../BlogMain/blogMainStyle.jsx'
import Section from '../Section/Section'
import StandardArticle from '../StandardArticle/StandardArticle'

const BlogMainSpecificCat = props => {

  const { classes } = props
  return (
    <Section paddingTop={0}>

      {props.allDatoCmsArticle ? props.allDatoCmsArticle.nodes.map(article => {
        return (
          <StandardArticle
            article={article}
            actLang={props.actLang}
          />
        )
      }) : null}

    </Section>
  )

}

export default withStyles(blogMainStyle)(BlogMainSpecificCat)
